<template>
	<div>
		<page-bar name="Edit Brand">
			<template v-slot:actions>
				<v-btn @click="closeDialog = true" text>Close</v-btn>
			</template>
		</page-bar>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-spacer></v-spacer>
				<v-col cols="12" md="4">
					<v-row>
						<v-col cols="12">
							<v-card :elevation="cardElevation" outlined>
								<v-toolbar class="rounded-t" flat>
									<v-toolbar-title class="d-flex align-center">
										Details
									</v-toolbar-title>
									<v-spacer></v-spacer>
									<v-btn @click="updateBrand(editing)" color="primary">
										<v-icon>mdi-content-save</v-icon>
									</v-btn>
								</v-toolbar>
								<v-container fluid>
									<v-row dense>
										<v-col cols="12">
											<v-text-field v-model="editing.name" autocomplete="new-password" counter="200"
														  dense label="Name" outlined></v-text-field>
										</v-col>
										<v-col cols="12">
											<v-textarea v-model="editing.details" auto-grow autocomplete="new-password"
														counter="2000" dense label="Description"
														outlined></v-textarea>
										</v-col>
										<v-col cols="12">
											<v-select v-model="editing.brandGroupId" :items="brandGroups" dense
													  item-text="name" item-value="id" label="Group"
													  outlined></v-select>
										</v-col>
										<v-col cols="12">
											<v-text-field v-model="tag" append-outer-icon="mdi-plus" autocomplete="off"
														  dense hide-details label="Add Tag" outlined
														  @click:append-outer="addTag"
														  @keydown.enter="addTag"></v-text-field>
										</v-col>
										<v-col cols="12">
											<template v-for="t in editing.tags">
												<v-chip class="ma-2" close @click:close="removeTag(t)">
													{{ t }}
												</v-chip>
											</template>
										</v-col>
										<v-col cols="12">
											<v-row dense>
												<v-col cols="auto">
													<v-switch v-model="editing.featured" class="mr-4" dense
															  hide-details inset label="Featured"></v-switch>
												</v-col>
												<v-col cols="auto">
													<v-switch v-model="editing.active" dense hide-details inset
															  label="Active"></v-switch>
												</v-col>
												<v-spacer></v-spacer>
											</v-row>
										</v-col>
									</v-row>
								</v-container>
							</v-card>
						</v-col>

						<v-col cols="12">
							<v-card :elevation="cardElevation" outlined>
								<v-toolbar class="rounded-t" flat>
									<v-toolbar-title class="d-flex align-center">
										Logo
									</v-toolbar-title>
									<v-spacer></v-spacer>
									<v-btn :disabled="!logo || disableUpload" @click="triggerUploadLogo"
										   color="primary">
										<v-icon>mdi-cloud-upload</v-icon>
									</v-btn>
								</v-toolbar>
								<v-container fluid>
									<v-row dense>
										<v-col cols="12">
											<v-file-input v-model="logo" dense hide-details label="Click to select logo"
														  outlined small-chips></v-file-input>
										</v-col>
										<v-col cols="12">
											<v-row dense>
												<v-spacer></v-spacer>
												<v-col v-if="editing.logo" cols="12" md="6">
													<v-sheet class="pa-4" outlined rounded>
														<v-img :src="editing.logo" max-height="250px"></v-img>
													</v-sheet>
												</v-col>
												<v-spacer></v-spacer>
											</v-row>
										</v-col>
									</v-row>
								</v-container>
							</v-card>
						</v-col>
					</v-row>

				</v-col>
				<v-col cols="12" md="8">
					<v-row dense>
						<v-col cols="12">
							<brand-images :brand-id="editing.id"></brand-images>
						</v-col>
					</v-row>
				</v-col>
				<v-spacer></v-spacer>
			</v-row>
		</v-container>

		<card-dialog :showing="closeDialog" @cancel="closeDialog = false" @confirmed="confirmedClose" confirm-text="Close"
					 title="Confirm">
			<template v-slot:dialog-body>
				Are you sure you want to close this window?
			</template>
		</card-dialog>

	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import PageBar from '@/components/global/PageBar'
import CardDialog from '@/components/global/CardDialog'
import BrandImages from '@/components/appliance/BrandImages'

export default {
	name: 'BrandsEdit',
	components: {BrandImages, CardDialog, PageBar},
	data: () => ({
		editing: {},
		tag: null,
		logo: null,
		disableUpload: false,
		closeDialog: false,
	}),
	created() {
		if (this.brandGroups.length === 0) this.listBrandGroups()
		this.editing = this.brand
	},
	methods: {
		...mapActions('appliance/brand', [
			'uploadBrandLogo',
			'updateBrand',
		]),
		...mapActions('appliance/brandGroup', [
			'listBrandGroups'
		]),
		triggerUploadLogo() {
			this.disableUpload = true
			this.uploadBrandLogo({id: this.editing.id, file: this.logo}).then(res => {
				this.editing.logo = res
				this.logo = null
			}).finally(() => {
				this.disableUpload = false
			})
		},
		addTag() {
			if (!this.tag) {
				return
			}
			this.editing.tags.push(this.tag)
			this.tag = null
		},
		removeTag(tag) {
			const index = this.editing.tags.indexOf(tag)
			if (index > -1) {
				this.editing.tags.splice(index, 1)
			}
		},

		confirmedClose() {
			this.closeDialog = false
			this.$router.back()
		},
	},
	computed: {
		...mapGetters('appliance/brand', [
			'brand',
			'updateStatus'
		]),
		...mapGetters('appliance/brandGroup', [
			'brandGroups'
		])
	},
	watch: {
		updateStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.alertSuccess('The brand has been updated')
			}
		}
	}
}
</script>

<style scoped>

</style>
