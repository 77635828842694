<template>
	<div>
		<v-card :elevation="cardElevation" outlined>
			<v-toolbar class="rounded-t" flat>
				<v-toolbar-title class="d-flex align-center">
					Images
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn :disabled="!canSave" @click="resequenceBrandImages(images)" class="mr-2" color="primary">
					<v-icon>mdi-content-save</v-icon>
				</v-btn>
				<v-btn @click="dialogMode = 'create'" color="primary">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-toolbar>
			<v-container fluid>
				<draggable v-model="images" @end="dragEnd" class="row" handle=".handle">
					<template v-for="(img, index) in images">
						<v-col :key="index" cols="12" md="3">
							<v-hover>
								<template v-slot:default="{ hover }">
									<v-card :elevation="cardElevation" align="center" class="pa-0" height="200"
											justify="center">
										<v-img v-if="img.imageUrl" :src="img.imageUrl" height="200"
											   style="border-radius: 4px;"></v-img>
										<missing-image v-else></missing-image>
										<v-fade-transition>
											<v-overlay v-if="hover" absolute>
												<div class="d-flex fill-height"
													 style="flex-direction: column; position: absolute;">
													<div class="d-flex pl-2 pt-2">
														<v-icon class="handle">mdi-crosshairs-gps</v-icon>
														<v-spacer></v-spacer>
													</div>
												</div>
												<div class="d-flex fill-height" style="flex-direction: column;">
													<div class="d-flex fill-height align-center justify-center">
														<v-btn v-if="img.imageUrl" @click="previewUrl = img.imageUrl"
															   icon>
															<v-icon>mdi-arrow-expand</v-icon>
														</v-btn>
														<v-btn @click="editBrandImage(img)" icon>
															<v-icon>mdi-pencil</v-icon>
														</v-btn>
														<v-btn @click="uploadBrandImageId = img.id" icon>
															<v-icon>mdi-cloud-upload</v-icon>
														</v-btn>
														<v-btn @click="removeBrandImageId = img.id" icon>
															<v-icon>mdi-delete</v-icon>
														</v-btn>
													</div>
												</div>
											</v-overlay>
										</v-fade-transition>
									</v-card>
								</template>
							</v-hover>
						</v-col>
					</template>

				</draggable>
			</v-container>
		</v-card>

		<card-dialog :showing="dialogMode !== 'hidden'" @cancel="closeBrandImageDialog"
					 @confirmed="confirmedBrandImageDialog" :confirm-disabled="!valid" confirm-text="Save"
					 max-width="600px" title="Brand Image">
			<template v-slot:dialog-body>
				<v-form v-model="valid" ref="brandImageForm">
					<v-row dense>
						<v-col cols="12">
							<v-text-field v-model="brandImage.title" :rules="[validation.required, validation.notEmpty]"
										  autocomplete="off" counter="200" dense label="*Title" outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="brandImage.description" auto-grow autocomplete="new-password"
										counter="500" dense label="Description" outlined></v-textarea>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="auto">
							<v-switch v-model="brandImage.showTitle" class="mr-4" dense hide-details inset
									  label="Show Title"></v-switch>
						</v-col>
						<v-col cols="auto">
							<v-switch v-model="brandImage.showDescription" class="mr-4" dense hide-details inset
									  label="Show Description"></v-switch>
						</v-col>
						<v-col v-if="dialogMode === 'edit'" cols="auto">
							<v-switch v-model="brandImage.active" dense hide-details inset label="Active"></v-switch>
						</v-col>
					</v-row>
				</v-form>
			</template>
		</card-dialog>

		<card-dialog :showing="previewUrl !== null" max-width="800px" title="Image Preview">
			<template v-slot:dialog-body>
				<v-img :src="previewUrl"></v-img>
			</template>
			<template v-slot:actions>
				<v-spacer></v-spacer>
				<v-btn @click="previewUrl = null" color="primary" text>Close</v-btn>
			</template>
		</card-dialog>

		<card-dialog :showing="uploadBrandImageId !== null" @cancel="uploadBrandImageId = null"
					 @confirmed="uploadBrandImage({id: uploadBrandImageId, file: image})"
					 :confirm-disabled="image === null" confirm-text="Upload" max-width="600px"
					 title="Upload Brand Image">
			<template v-slot:dialog-body>
				<v-form ref="imageUploadForm">
					<v-file-input v-model="image" :rules="[validation.required, validation.maxSize]"
								  :loading="uploadStatus === status.RUNNING" accept="image/*" dense
								  label="Click to select image" outlined show-size small-chips></v-file-input>
				</v-form>
			</template>
		</card-dialog>

		<card-dialog :showing="removeBrandImageId !== null" @cancel="removeBrandImageId = null"
					 @confirmed="removeBrandImage(removeBrandImageId)" confirm-color="red" confirm-text="Delete"
					 title="Delete Brand Image" title-color="red">
			<template v-slot:dialog-body>
				Are you sure you want to delete this brand image? This action cannot be undone.
			</template>
		</card-dialog>
	</div>
</template>

<script>
import MissingImage from '@/components/global/MissingImage'
import draggable from 'vuedraggable'
import CardDialog from '@/components/global/CardDialog'
import {mapActions, mapGetters} from 'vuex'

export default {
	name: 'BrandImages',
	components: {CardDialog, draggable, MissingImage},
	props: {
		brandId: {type: Number, required: true},
	},
	data: () => ({
		images: [],
		brandImage: {
			title: null,
			description: null,
			showTitle: false,
			showDescription: false,
			active: false,
		},
		image: null,
		valid: false,
		canSave: false,
		uploadBrandImageId: null,
		removeBrandImageId: null,
		previewUrl: null,
		dialogMode: 'hidden',
		createDialog: false,
	}),
	created() {
		this.listBrandImages(this.brandId)
	},
	beforeDestroy() {
		this.clearBrandImages()
	},
	methods: {
		...mapActions('appliance/brandImage', [
			'clearBrandImages',
			'listBrandImages',
			'createBrandImage',
			'updateBrandImage',
			'uploadBrandImage',
			'resequenceBrandImages',
			'removeBrandImage'
		]),
		editBrandImage(item) {
			this.brandImage = {...item}
			this.dialogMode = 'edit'
		},
		confirmedBrandImageDialog() {
			if (!this.valid) return
			if (this.dialogMode === 'create') {
				this.createBrandImage({
					brandId: this.brandId,
					title: this.brandImage.title,
					description: this.brandImage.description,
					showTitle: this.brandImage.showTitle,
					showDescription: this.brandImage.showDescription,
					sequence: this.brandImages.length + 1,
				})
			} else if (this.dialogMode === 'edit') {
				this.updateBrandImage({
					id: this.brandImage.id,
					title: this.brandImage.title,
					description: this.brandImage.description,
					showTitle: this.brandImage.showTitle,
					showDescription: this.brandImage.showDescription,
					active: this.brandImage.active,
				})
			}
		},
		dragEnd(data) {
			if (this.canSave) return
			if (data.newIndex !== data.oldIndex) {
				this.canSave = true
			}
		},
		closeBrandImageDialog() {
			this.brandImage = {
				title: null,
				description: null,
				showTitle: false,
				showDescription: false,
				active: false,
			}
			this.$refs.brandImageForm.resetValidation()
			this.dialogMode = 'hidden'
		}
	},
	computed: {
		...mapGetters('appliance/brandImage', [
			'brandImages',
			'listStatus',
			'createStatus',
			'updateStatus',
			'uploadStatus',
			'resequenceStatus',
			'removeStatus'
		]),
	},
	watch: {
		listStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.images = [...this.brandImages]
			}
		},
		resequenceStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.canSave = false
			}
		},
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				/*this.$refs.brandImageForm.reset()
				this.$refs.brandImageForm.resetValidation()
				this.dialogMode = 'hidden'*/
				this.closeBrandImageDialog()
				this.listBrandImages(this.brandId)
			}
		},
		updateStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				/*this.brandImage = {
					title: null,
					description: null,
					showTitle: false,
					showDescription: false,
				}
				this.dialogMode = 'hidden'*/
				this.closeBrandImageDialog()
				this.listBrandImages(this.brandId)
			}
		},
		uploadStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.uploadBrandImageId = null
				this.image = null
				this.listBrandImages(this.brandId)
			}
		},
		removeStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.removeBrandImageId = null
				this.listBrandImages(this.brandId)
			}
		},
		uploadBrandImageId(updated) {
			if (updated === null) {
				this.$refs.imageUploadForm.resetValidation()
			}
		}
	}
}
</script>

<style>
.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.v-overlay__content {
	height: 100%;
	width: 100%;
}


.handle {
}

.handle:hover {
	cursor: grab;
}

.handle:active {
	cursor: grabbing;
}
</style>
